import React from 'react';
import { Navigate } from 'react-router-dom';
import { myLoginService } from '@/services/login.service';
import includes from 'lodash/includes';

interface UserRoleProps {
    user: string;
    children: React.ReactNode;
}

const UserRole: React.FC<UserRoleProps> = ({ user, children }) => {
    const userRoleUrl = myLoginService.getUserRoleUrl();
    if (includes(userRoleUrl, user)) {
        if (user == '/addtional-info') {
            const queryParams = new URLSearchParams(window.location.search);
            const loginEmail = queryParams.get('loginEmail');
            const absoluteUrl = new URL(userRoleUrl, window.location.origin);
            const userRoleEmail = absoluteUrl.searchParams.get('loginEmail') || '';
            if (loginEmail !== userRoleEmail) {
                window.location.href = myLoginService.getUserRoleUrl();
                return null;
            }

            return <>{children}</>;
        } 
    }else {
        return <Navigate to={userRoleUrl} />;
    }

    return <>{children}</>;

};

export default UserRole;